
import {Component, Vue} from "vue-property-decorator";
import {projectInstance} from "@/shared/project-instance";
import {bus} from "@/shared";
import Events from "@/constants/Events";
import {trackMoveTypeToText} from "@/utils/MoveUtils";

@Component({
  methods: {trackMoveTypeToText}
})
export default class MovesDock extends Vue {
  protected currentVehicleTrackIndex: number = 0;

  get moves() {
    return projectInstance.projectState.vehicleTracks[this.currentVehicleTrackIndex].moves;
  }

  protected rollbackMove(index: number): void {
    this.$confirm.require({
      header: index ===0 ? '清空' : '回退',
      message: index ===0 ? '是否完全清空该行车移动？' : '是否回退至此步骤？',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        bus.$emit(Events.ROLLBACK_MOVE, index);
      },
    });
  }

  mounted(): void {
    bus.$on(Events.CHANGE_VEHICLE_INDEX, (index: number) => {
      this.currentVehicleTrackIndex = index;
    });
  }
}
