import {GetScheduleParam, ScheduleParameters, UpdateScheduleParam} from "@/shared/dtos";
import {client} from "@/shared";

export default class ScheduleParametersService {
    public static get(projectName: string): Promise<ScheduleParameters> {
        return client.get(new GetScheduleParam({ projectName }));
    }

    public static put(projectName: string, scheduleParameters: ScheduleParameters): Promise<void> {
        return client.put(new UpdateScheduleParam({ scheduleParameters, projectName }));
    }
}