import {Craft, CraftParameterModel} from "@/shared/dtos";
import {client} from "@/shared";

export default class CraftService {
    public static putAll(projectName: string, cycleTime: number, crafts: Craft[]): Promise<void> {
        const request: CraftParameterModel = new CraftParameterModel({
            projectName,
            cycleTime,
            craftCount: crafts.length,
            crafts
        });
        return client.put(request);
    }

    public static post(projectName: string, crafts: Craft[]): Promise<void> {
        const request: CraftParameterModel = new CraftParameterModel({
            projectName,
            craftCount: crafts.length,
            crafts
        });
        return client.post(request);
    }
}
