/**
 * @file MoveUtils.ts
 * @description TrackMove工具类
 * @author Riven
 * @date 2023-04-25
 * @version V1.0.0
 */
import {TrackMoveType} from "@/shared/dtos";

/**
 * TrackMoveType转换为文字
 *
 * @param type
 * @returns {string} 文字
 */
function trackMoveTypeToText(type: string): string {
    switch (type) {
        case TrackMoveType.MoveUp:
            return "上升";
        case TrackMoveType.MoveDown:
            return "下降";
        case TrackMoveType.MoveNext:
            return "水平向后";
        case TrackMoveType.Waiting:
            return "等待";
        case TrackMoveType.MovePrevious:
            return "水平向前";
        default:
            return "未知";
    }
}

export {trackMoveTypeToText};