import NotificationEventBus from './NotificationEventBus';
import {Notification} from "@/shared/dtos";

interface NotificationDialogServiceMethods {
    require(options: any): any;
    close(): void;
}

declare module 'vue/types/vue' {
    interface Vue {
        $notify: NotificationDialogServiceMethods;
    }
}

const NotificationDialogService = {
    install: (Vue: any) => {
        Vue.prototype.$notify = {
            require: (options: Notification) => {
                NotificationEventBus.emit('notify', options);
            },
            close: () => {
                NotificationEventBus.emit('close');
            }
        };
    }
};

export default NotificationDialogService;
