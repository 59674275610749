<script setup>
import {ref} from 'vue';

const visible = ref(false);
const onConfigButtonClick = () => {
  visible.value = !visible.value;
};

</script>

<template>
  <div>
    <button class="layout-config-button p-link" type="button" @click="onConfigButtonClick()">
      <i class="pi pi-cog"></i>
    </button>

    <Sidebar :visible.sync="visible" position="right" class="layout-config-sidebar w-20rem">
      <slot></slot>
    </Sidebar>
  </div>
</template>

<style lang="scss" scoped></style>
