import 'bootstrap/dist/css/bootstrap.css';
import './app.css';
import 'es6-shim';
import Vue from 'vue';
import { Icon } from '@iconify/vue2';
import App from './App.vue';

import PrimeVue from 'primevue/config';
import "primevue/resources/themes/saga-blue/theme.css";       //theme
import "primevue/resources/primevue.min.css";                 //core css
import "primeicons/primeicons.css";                           //icons

import '@/assets/styles.scss';

Vue.use(PrimeVue, {
    locale: {
        accept: '是',
        reject: '否',
        //...
    }
});
Vue.use(loading);

// PrimeVue components imports
import Dropdown from "primevue/dropdown";
import Menubar from 'primevue/menubar';
import Sidebar from 'primevue/sidebar';
import RadioButton from 'primevue/radiobutton';
import SplitButton from 'primevue/splitbutton';
import Button from 'primevue/button';
import InputSwitch from 'primevue/inputswitch';
import InputNumber from "primevue/inputnumber";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import DataView from "primevue/dataview";
import OrderList from "primevue/orderlist";
import Toolbar from "primevue/toolbar";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Card from "primevue/card";
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmDialog from 'primevue/confirmdialog';
import Toast from 'primevue/toast';
import ContextMenu from 'primevue/contextmenu';
import Message from 'primevue/message';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Divider from "primevue/divider";
import OverlayPanel from 'primevue/overlaypanel';
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";

Vue.component("Dropdown", Dropdown);
Vue.component("Menubar", Menubar);
Vue.component("Sidebar", Sidebar);
Vue.component("RadioButton", RadioButton);
Vue.component("SplitButton", SplitButton);
Vue.component("Button", Button);
Vue.component("InputSwitch", InputSwitch);
Vue.component("InputNumber", InputNumber);
Vue.component("DataView", DataView);
Vue.component("Dialog", Dialog);
Vue.component("InputText", InputText);
Vue.component("OrderList", OrderList);
Vue.component("Toolbar", Toolbar);
Vue.component("DataTable", DataTable);
Vue.component("Column", Column);
Vue.component("Card", Card);
Vue.component("ConfirmPopup", ConfirmPopup);
Vue.component("ConfirmDialog", ConfirmDialog);
Vue.component("Toast", Toast);
Vue.component("ContextMenu", ContextMenu);
Vue.component("Message", Message);
Vue.component("TabView", TabView);
Vue.component("TabPanel", TabPanel);
Vue.component("Divider", Divider);
Vue.component("OverlayPanel", OverlayPanel);
Vue.component("Splitter", Splitter);
Vue.component("SplitterPanel", SplitterPanel);

// PrimeVue Services
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';

Vue.use(ConfirmationService);
Vue.use(ToastService);

import Tooltip from 'primevue/tooltip';

Vue.directive('tooltip', Tooltip);

import NotificationDialogService from "@/common/notification/NotificationDialogService";
Vue.use(NotificationDialogService);

import NotificationDialog from "@/common/notification/NotificationDialog.vue";
Vue.component('NotificationDialog', NotificationDialog);

import Controls from '@servicestack/vue';
Vue.use(Controls);

Vue.component('Icon', Icon)


import { router } from './shared/router';
import loading from "@/directives/loading";

Vue.config.productionTip = false;

const app = new Vue({
    el: '#app',
    render: (h) => h(App),
    router,
});
