
import {Component, Vue} from "vue-property-decorator";
import {projectInstance} from "@/shared/project-instance";
import {ProjectPhase} from "@/shared/dtos";
import {router, Routes} from "@/shared/router";
import ScheduleChart from "@/components/Charts/ScheduleChart.vue";

@Component({
  components: {
    ScheduleChart
  }
})
export default class SchedulePanel extends Vue {

  protected backToCraftConfigure() {
    projectInstance.$set(projectInstance.projectState, "phase", ProjectPhase.CraftEntry);
    router.push({path: `${Routes.Studio}/${Routes.Configure}`});
  }
}
