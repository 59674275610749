
import Vue from "vue";
import {Component} from "vue-property-decorator";
import TopToolbar from "./Toolbar/TopToolbar.vue";
import AppLayout from "@/components/Layout/AppLayout.vue";
import ScheduleContext from "@/components/Studio/ScheduleContext/index.vue";
import Configuration from "@/components/Studio/Configuration/index.vue";
import {bus, client, store} from "@/shared";
import Events from "@/constants/Events";
import {
  CraftParameterModel,
  Crafts,
  GetAllProjects,
  ManageProject,
  Project,
  ProjectPhase
} from "@/shared/dtos";
import {initProjectInstance, projectInstance} from "@/shared/project-instance";
import {router, Routes} from "@/shared/router";
import {toFormData} from "@servicestack/client";
import ProjectService from "@/services/ProjectService";
import ScheduleService from "@/services/ScheduleService";
import {getUrlParams} from "@/utils/CommonUtils";

@Component({
  components: {
    AppLayout,
    TopToolbar,
    ScheduleContext,
    Configuration
  },
})
export class Studio extends Vue {
  protected newProjectVisible: boolean = false;
  protected openProjectVisible: boolean = false;

  public projectName: string = ""

  protected projects: Project[] = [];

  protected selectedProject: Project = {
    name: "",
    createTime: "",
    lastModifiedTime: "",
    creator: "",
    projectPhase: ProjectPhase.ProjectNotInit,
    sharedTo: ""
  };

  created() {
    this.initEvents();
  }

  mounted() {
    const params = getUrlParams(window.location.search);
    console.log(params);
    if (params.project_name) {
      this.loadProjectInstance(decodeURIComponent(params.project_name));
    }
  }

  private initEvents() {
    bus.$on(Events.NEW_PROJECT, () => {
      this.newProjectVisible = true;
    });
    bus.$on(Events.OPEN_PROJECT, () => {
      this.openProjectVisible = true;
      client.post(new GetAllProjects({creator: store.userSession?.userId})).then((result) => {
        this.projects = result.projects;
      });
    });
    bus.$on(Events.IMPORT_CRAFT_PARAMETERS, () => {
      const fileElement: HTMLElement = this.$refs.file as HTMLElement;
      fileElement && fileElement.click();
    });
    bus.$on(Events.SAVE_PROJECT, () => {
      const request = new CraftParameterModel({
        projectName: projectInstance.projectState.name,
        crafts: projectInstance.projectState.crafts
      });
      client.post(request).then(() => {
        this.$toast.add({severity: 'success', summary: '保存成功', detail: '成功保存工艺参数。', life: 3000});
      });
    });
  }

  protected createProject(): void {
    const request = new ManageProject();
    request.name = this.projectName;
    request.creator = store.userSession?.userId as string;
    client.post(request).then((result) => {
      if (result.responseStatus && result.responseStatus.errorCode === "20001001") {
        this.$toast.add({severity: 'error', summary: '创建失败！', detail: '工程名已存在，请更换工程名', life: 4000});
        return;
      }
      this.newProjectVisible = false;
      if (projectInstance.projectState.name) {
        window.open(Routes.Studio + "?project_name=" + this.projectName, "_self");
        return;
      }
      initProjectInstance();
      projectInstance.$set(projectInstance.projectState, "name", this.projectName);
      projectInstance.$set(projectInstance.projectState, "phase", ProjectPhase.CraftEntry);
      projectInstance.projectState.schedule.crafts.splice(0, projectInstance.projectState.schedule.crafts.length);
      router.push({path: `${Routes.Studio}/${Routes.Configure}`});
      bus.$emit(Events.NEW_PROJECT_CREATED, this.projectName);
    });
  }

  protected loadProject(): void {
    if (projectInstance.projectState.name) {
      window.open(Routes.Studio + "?project_name=" + this.selectedProject.name, "_self");
    } else {
      this.loadProjectInstance();
    }
  }

  protected loadProjectInstance(name: string = this.selectedProject.name): void {
    ProjectService.get(new ManageProject({name})).then((result) => {
      initProjectInstance();
      projectInstance.$set(projectInstance.projectState, "name", result.name);
      projectInstance.$set(projectInstance.projectState, "phase", result.projectPhase);
      projectInstance.projectState.crafts.splice(0, projectInstance.projectState.crafts.length);
      projectInstance.projectState.crafts.push(...result.craftParameterModel.crafts);
      projectInstance.projectState.cycleTime = result.craftParameterModel.cycleTime;
      projectInstance.projectState.craftCount = result.craftParameterModel.craftCount;
      switch (result.projectPhase) {
        case ProjectPhase.Scheduling:
          ScheduleService.retrieveSchedule(projectInstance.projectState.name).then((result) => {
            projectInstance.projectState.slots = result.slots;
            projectInstance.projectState.scheduleParameters = result.scheduleParameters;
            projectInstance.projectState.vehicleTracks = result.vehicleTracks;
            router.push({path: `${Routes.Studio}/${Routes.SchedulePanel}`});
          });
          break;
        case ProjectPhase.CraftEntry:
        default:
          router.push({path: `${Routes.Studio}/${Routes.Configure}`});
      }
    });
    this.openProjectVisible = false;
  }

  protected onFileChange(): void {
    const fileElement: HTMLInputElement = this.$refs.file as HTMLInputElement;
    // 检测到文件导入，将工艺配置发送到后台
    if (fileElement && fileElement.files && fileElement.files.length > 0) {
      const file = fileElement.files[0];
      client.postBody(new Crafts({projectName: projectInstance.projectState.name}), toFormData({file})).then((result) => {
        projectInstance.projectState.schedule.crafts.push(...result.schedule.crafts);
        projectInstance.projectState.schedule.cycleTime = result.schedule.cycleTime;
        projectInstance.projectState.schedule.craftCount = result.schedule.craftCount;
        this.$toast.add({severity: 'success', summary: '导入成功', detail: '成功导入工艺参数，并已自动保存。', life: 3000});
      })
    }
  }
}

export default Studio;
