import Vue from 'vue';
import Loading from './loading.vue';

const Mask = Vue.extend(Loading);

const toggleLoading = (el: any, binding: any) => {
    if (binding.value) {
        Vue.nextTick(() => {
            // 控制loading组件显示
            el.instance.visible = true;
            // 插入到目标元素
            insertDom(el, el, binding);
        })
    } else {
        el.instance.visible = false;
    }
}

const insertDom = (parent: any, el: any, binding: any) => {
    parent.appendChild(el.mask)
}

export default {
    bind: function (el: any, binding: any, vnode: any) {
        const mask: Vue = new Mask({
            el: document.createElement('div'),
            data() { }
        })
        el.instance = mask;
        el.mask = mask.$el;
        el.maskStyle = {};
        binding.value && toggleLoading(el, binding);
    },
    update: function (el: any, binding: any) {
        if (binding.oldValue !== binding.value) {
            toggleLoading(el, binding);
        }
    },
    unbind: function (el: any, binding: any) {
        el.instance && el.instance.$destroy();
    }
}
