
import { Vue, Component } from 'vue-property-decorator';
import { store, signout } from '../shared';

@Component
export class Profile extends Vue {

  protected get user() {
    return store.userSession;
  }

  protected onSignOut() { signout(); }
}
export default Profile;
