import { render, staticRenderFns } from "./AppSidebar.vue?vue&type=template&id=21e65b8e&scoped=true&"
import script from "./AppSidebar.vue?vue&type=script&setup=true&lang=js&"
export * from "./AppSidebar.vue?vue&type=script&setup=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21e65b8e",
  null
  
)

export default component.exports