
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import { LineSeriesOption } from "echarts/charts";
import { Craft, CraftPlan, ScheduleResponse } from "@/shared/dtos";
import SingleChart from "./SingleChart.vue";
import { EChartsOption } from "echarts/types/dist/shared";
import {bus} from "@/shared";
import Events from "@/constants/Events";

@Component({
  components: {
    SingleChart,
  },
})
export class SingleChartsCollection extends Vue {
  @Prop({ default: new ScheduleResponse() }) data: ScheduleResponse;
  @Prop({ default: null }) cycleTime: number;
  @Prop({ default: [] }) crafts: Craft[];

  public chartOptions: EChartsOption[] = [];

  @Watch("data")
  public onDataChanged(value: ScheduleResponse) {
    this.chartOptions = [];

    this.handleResultData(value);
  }

  mounted() {
    bus.$on(Events.SHOW_PREVIOUS_DATA, (id: string) => {
      const index = parseInt(id);
      if (index < 1) {
        return;
      }
      let series = this.chartOptions[index].series;
      const seriesPrevious = this.chartOptions[index - 1].series;
      // @ts-ignore
      let seriesNew = series.concat(seriesPrevious);
      this.chartOptions[index].series = seriesNew;
      bus.$emit(Events.REFRESH_CHART);
    });
    this.handleResultData(this.data);
  }

  public handleResultData(plan: ScheduleResponse) {
    const craftPlans: CraftPlan[] = plan.lineCrafts;

    for (let i = 0; i < plan.movingTracks.length; i++) {
      let start = plan.movingTracks[i].startPosition;
      let end = plan.movingTracks[i].endPosition;
      let track = plan.movingTracks[i].tracks;
      const diff = plan.movingTracks[i].diff;
      let pointCollection: number[][] = [];

      // @ts-ignore
      for (let j = 0; j < diff.length; j++) {
        // @ts-ignore
        if (diff[j] !== 0) {
          // @ts-ignore
          let y = Math.floor(track[j] / 10);
          pointCollection.push([j, y]);
        }
      }

      const seriesOption: LineSeriesOption = {
        name: "行车轨迹",
        type: "line",
        data: pointCollection,
        color: "red",
      };

      let tempSeries: LineSeriesOption[] = [];
      tempSeries.push(seriesOption);

      tempSeries = tempSeries.concat(
        this.generateCraftPlans(craftPlans, this.cycleTime, start, end)
      );

      this.chartOptions.push({
        title: {
          text: "车" + (i + 1),
        },
        xAxis: {
          max: this.cycleTime,
        },
        series: tempSeries,
      });
    }
  }

  private generateCraftPlans(
    plans: CraftPlan[],
    cycleTime: number,
    start: number,
    end: number
  ): LineSeriesOption[] {
    const craftSeriesOption: LineSeriesOption[] = [];

    for (let index = start; index < end; index++) {
      const plan = plans[index];
      if (plan.startTime) {
        // 正向描述的工艺
        if (plan.startTime <= plan.endTime) {
          let pointCollection: number[][] = [];
          pointCollection.push([plan.startTime, index + 1]);
          pointCollection.push([plan.endTime, index + 1]);
          craftSeriesOption.push({
            type: "line",
            name: `工艺${index + 1}: ${this.crafts[index].name}`,
            data: pointCollection,
            color: "blue",
          });
        } else {
          let firstPointCollection: number[][] = [];
          firstPointCollection.push([0, index + 1]);
          firstPointCollection.push([plan.endTime, index + 1]);
          craftSeriesOption.push({
            type: "line",
            name: `工艺${index + 1}: ${this.crafts[index].name}`,
            data: firstPointCollection,
            color: "blue",
          });

          let secondPointCollection: number[][] = [];
          secondPointCollection.push([plan.startTime, index + 1]);
          secondPointCollection.push([cycleTime, index + 1]);
          craftSeriesOption.push({
            type: "line",
            name: `工艺${index + 1}: ${this.crafts[index].name}`,
            data: secondPointCollection,
            color: "blue",
          });
        }
      }
    }

    return craftSeriesOption;
  }
}
export default SingleChartsCollection;
