import {client} from "@/shared";
import {
    Craft,
    CraftOperate,
    CraftOperateResult,
    RetreiveOperate,
    RetreiveOperateResult,
    VehicleAutoOperate,
    VehicleAutoOperateResult,
    VehicleAutoOperateType,
    VehicleMoveType,
    VehicleOperate,
    VehicleOperateResult
} from "@/shared/dtos";

export default class ScheduleService {
    public static retrieveSchedule(projectName: string): Promise<RetreiveOperateResult> {
        return client.post(new RetreiveOperate({
            projectName
        }));
    }

    public static editCraft(projectName: string, crafts: Craft[]): Promise<CraftOperateResult> {
        return client.post(new CraftOperate({
            projectName,
            crafts,
        }));
    }

    public static addNewVehicle(projectName: string, vehicleIndex: number, specificMoveTime: number): Promise<VehicleOperateResult> {
        const request = new VehicleOperate({
            projectName,
            vehicleIndex,
            specificMoveTime,
            vehicleMoveType: VehicleMoveType.AddNewVehicle
        });
        return client.post(request);
    }

    public static generateTrack(projectName: string, vehicleIndex: number, specificMoveTime: number): Promise<VehicleOperateResult> {
        const request = new VehicleOperate({
            projectName,
            vehicleIndex,
            vehicleMoveType: VehicleMoveType.GenerateTrack
        });
        return client.post(request);
    }

    public static generateAllTrack(projectName: string): Promise<VehicleAutoOperateResult> {
        const request = new VehicleAutoOperate({
            projectName,
            type: VehicleAutoOperateType.GenerateAllTrack,
        });
        return client.post(request);
    }

    public static vehicleMoveUp(projectName: string, vehicleIndex: number, specificMoveTime: number): Promise<VehicleOperateResult> {
        const request = new VehicleOperate({
            projectName,
            vehicleIndex,
            specificMoveTime,
            vehicleMoveType: VehicleMoveType.MoveUp
        });
        return client.post(request);
    }

    public static vehicleMoveUpToMiddle(projectName: string, vehicleIndex: number, specificMoveTime: number): Promise<VehicleOperateResult> {
        const request = new VehicleOperate({
            projectName,
            vehicleIndex,
            specificMoveTime,
            vehicleMoveType: VehicleMoveType.MoveUpToMiddle
        });
        return client.post(request);
    }

    public static vehicleMoveDown(projectName: string, vehicleIndex: number, specificMoveTime: number): Promise<VehicleOperateResult> {
        const request = new VehicleOperate({
            projectName,
            vehicleIndex,
            specificMoveTime,
            vehicleMoveType: VehicleMoveType.MoveDown
        });
        return client.post(request);
    }

    public static vehicleMoveToNext() {}

    public static vehicleMoveHorizontal(projectName: string, vehicleIndex: number, horizontalOffset: number, specificMoveTime: number) {
        const request = new VehicleOperate({
            projectName,
            vehicleIndex: vehicleIndex,
            specificMoveTime: specificMoveTime,
            horizontalOffset: horizontalOffset,
            vehicleMoveType: VehicleMoveType.MoveHorizontal
        });
        return client.post(request);
    }

    public static vehicleWait(projectName: string, vehicleIndex: number, waitTime: number) {
        const request = new VehicleOperate({
            projectName,
            vehicleIndex: vehicleIndex,
            specificMoveTime: waitTime,
            vehicleMoveType: VehicleMoveType.Waiting
        });
        return client.post(request);
    }

    public static vehicleRollback(projectName: string, vehicleIndex: number, rollbackMoveIndex: number) {
        const request = new VehicleOperate({
            projectName,
            vehicleIndex: vehicleIndex,
            specificMoveTime: rollbackMoveIndex + 1,
            vehicleMoveType: VehicleMoveType.Rollback
        });
        return client.post(request);
    }
}
