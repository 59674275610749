
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { store, bus, client, checkAuth, toPascalCase, splitOnFirst } from '../shared';
import { Register } from '../shared/dtos';
import { Routes, redirect } from '../shared/router';

@Component
export class SignUp extends Vue {

    displayName = '';
    email = '';
    userName = '';
    password = '';
    confirmPassword = '';
    autoLogin = true;
    loading = false;
    responseStatus = null;

    async submit() {
        try {
            this.loading = true;
            this.responseStatus = null;

            const response = await client.post(new Register({
                displayName: this.displayName,
                email: this.email,
                password: this.password,
                confirmPassword: this.confirmPassword,
                autoLogin: this.autoLogin,
            }));

            await checkAuth();

            redirect(Routes.Home);

        } catch (e: any) {
            this.responseStatus = e.responseStatus || e;
        } finally {
            this.loading = false;
        }
    }

    newUser(email: string) {
        const names = email.split('@');
        this.displayName = toPascalCase(names[0]) + ' ' + toPascalCase(splitOnFirst(names[1], '.')[0]);
        this.email = email;
        this.password = this.confirmPassword = 'p@55wOrd';
    }

}
export default SignUp;
