
import {Component, Prop, Vue} from "vue-property-decorator";
import {projectInstance} from "@/shared/project-instance";
import {ScheduleParameters} from "@/shared/dtos";
import ScheduleParametersService from "@/services/ScheduleParametersService";

@Component
export default class ScheduleParametersForm extends Vue {
  @Prop({default: false}) visible!: boolean;

  protected scheduleParameters: ScheduleParameters = new ScheduleParameters();

  get advisedHorizontalMoveTime() {
    return Math.round(this.scheduleParameters.horizontalDistance / this.scheduleParameters.horizontalSpeed * 60);
  }

  get advisedVerticalMoveTime() {
    return Math.round(this.scheduleParameters.verticalDistance / this.scheduleParameters.verticalSpeed * 60);
  }

  protected mounted() {
    ScheduleParametersService.get(projectInstance.projectState.name).then((result) => {
      this.scheduleParameters = result;
    });
  }

  protected save() {
    ScheduleParametersService.put(projectInstance.projectState.name, this.scheduleParameters).then(() => {
      this.$emit('saved');
    });
  }
}
