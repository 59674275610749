
import { Component, Vue } from 'vue-property-decorator';
import { store } from '../../shared';

@Component
export class Admin extends Vue {
  protected get user() {
    return store.userSession;
  }
}
export default Admin;
