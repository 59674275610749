/**
 * @file CommonUtils.ts
 * @description 通用工具类
 * @author Riven
 * @date 2023-06-02
 * @version V1.0.0
 */

export function getUrlParams(url: string): any {
    const params: any = {};
    const urlSplit = url.split('?');
    if (urlSplit.length > 1) {
        const paramsSplit = urlSplit[1].split('&');
        paramsSplit.forEach((item: string) => {
            const itemSplit = item.split('=');
            params[itemSplit[0]] = itemSplit[1];
        });
    }
    return params;
}
