
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { store, bus, client } from '../shared';
import { Authenticate } from '../shared/dtos';
import { redirect, Routes } from '../shared/router';

@Component
export class SignIn extends Vue {

    userName = '';
    password = '';
    rememberMe = true;
    loading = false;
    responseStatus = null;

    get store() { return store; }

    protected mounted() {
        if (this.store.userSession != null) {
            redirect(this.$route.query.redirect as string || Routes.Home);
        }
    }

    protected async submit() {
        try {
            this.loading = true;
            this.responseStatus = null;

            const response = await client.post(new Authenticate({
                provider: 'credentials',
                userName: this.userName,
                password: this.password,
                rememberMe: this.rememberMe,
            }));
            bus.$emit('signin', response);

            redirect(this.$route.query.redirect as string || Routes.Home);

        } catch (e: any) {
            this.responseStatus = e.responseStatus || e;
        } finally {
            this.loading = false;
        }
    }

    protected switchUser(email: string) {
        this.userName = email;
        this.password = 'p@55wOrd';
    }
}
export default SignIn;
