
import {Component, Prop, Vue} from "vue-property-decorator";
import {projectInstance} from "@/shared/project-instance";

@Component
export default class WaitConfigureForm extends Vue {
  @Prop({type: Number, required: true}) currentIndex!: number;

  get crafts() {
    return projectInstance.projectState.crafts;
  }

  get shouldMoveEmpty() {
    const shortMoveTime: number = projectInstance.projectState.scheduleParameters.verticalShortMoveTime;
    const horizontalMoveTime: number = projectInstance.projectState.scheduleParameters.horizontalMoveTime;
    console.log(this.waitTime, shortMoveTime, horizontalMoveTime);
    return this.waitTime >= shortMoveTime * 4 + horizontalMoveTime * 2;
  }

  public waitTime: number = 0;

  protected setWaitTime(index: number): void {
    this.waitTime = this.crafts[index].minTime;
  }
}
