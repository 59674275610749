/* Options:
Date: 2023-07-17 23:16:28
Version: 6.90
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:5001

//GlobalNamespace: 
//MakePropertiesOptional: False
//AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
//ExcludeTypes: 
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export enum PeriodType
{
    Upload = 'Upload',
    Download = 'Download',
    Normal = 'Normal',
    FurnaceImport = 'FurnaceImport',
    FurnaceOutlet = 'FurnaceOutlet',
}

export enum PeriodTimeType
{
    None = 'None',
    Short = 'Short',
    Long = 'Long',
    SuperLong = 'SuperLong',
}

export class Craft
{
    public index: number;
    public name?: string;
    public minTime: number;
    public maxTime: number;
    public periodType: PeriodType;
    public periodTimeType: PeriodTimeType;
    public belongTo: number;
    public isPlaced: boolean;
    public isTakenAway: boolean;
    public startTime: number;
    public endTime: number;
    public isHandOver: boolean;

    public constructor(init?: Partial<Craft>) { (Object as any).assign(this, init); }
}

export enum ProjectPhase
{
    ProjectNotInit = 'ProjectNotInit',
    NewProject = 'NewProject',
    CraftEntry = 'CraftEntry',
    Scheduling = 'Scheduling',
    PreliminaryCalculated = 'PreliminaryCalculated',
    InitialCalculation = 'InitialCalculation',
}

export class OperateBase
{
    public projectName?: string;

    public constructor(init?: Partial<OperateBase>) { (Object as any).assign(this, init); }
}

export class SchedulingContextBase
{
    public cycleTime: number;
    public craftCount: number;
    public crafts: Craft[];

    public constructor(init?: Partial<SchedulingContextBase>) { (Object as any).assign(this, init); }
}

export enum VehicleMoveType
{
    None = 'None',
    GenerateTrack = 'GenerateTrack',
    MoveUp = 'MoveUp',
    MoveUpToMiddle = 'MoveUpToMiddle',
    MoveDown = 'MoveDown',
    MoveHorizontal = 'MoveHorizontal',
    Waiting = 'Waiting',
    AddNewVehicle = 'AddNewVehicle',
    Rollback = 'Rollback',
}

export enum VehicleAutoOperateType
{
    None = 'None',
    GenerateAllTrack = 'GenerateAllTrack',
    ReGenerateAllTrack = 'ReGenerateAllTrack',
    RollbackAll = 'RollbackAll',
}

export enum SlotType
{
    Normal = 'Normal',
    Empty = 'Empty',
}

export class Slot
{
    public craftIndex: number;
    public slotType: SlotType;

    public constructor(init?: Partial<Slot>) { (Object as any).assign(this, init); }
}

export class SlotDto extends Slot
{
    public projectName: string;

    public constructor(init?: Partial<SlotDto>) { super(init); (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class ResponseBase implements IResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ResponseBase>) { (Object as any).assign(this, init); }
}

export interface IResponse
{
    responseStatus: ResponseStatus;
}

// @DataContract(Namespace="http://schemas.servicestack.net/types")
export class Project
{
    // @DataMember
    public name: string;

    // @DataMember
    public creator: string;

    // @DataMember
    public projectPhase: ProjectPhase;

    // @DataMember
    public createTime: string;

    // @DataMember
    public lastModifiedTime: string;

    // @DataMember
    public sharedTo: string;

    public constructor(init?: Partial<Project>) { (Object as any).assign(this, init); }
}

export class ArrayOfProject extends Array<Project>
{

    public constructor(init?: Partial<ArrayOfProject>) { super(); (Object as any).assign(this, init); }
}

export class TrackBase
{
    public diff?: number[];
    public index: number;
    public timeOffset: number;
    public tracks?: number[];
    public currentTime: number;
    public currentPosition: number;
    public currentMoveType: VehicleMoveType;

    public constructor(init?: Partial<TrackBase>) { (Object as any).assign(this, init); }
}

export enum SuggestionLevel
{
    Info = 'Info',
    Warn = 'Warn',
    Error = 'Error',
}

export enum SuggestionType
{
    CraftTimeTooShort = 'CraftTimeTooShort',
    CraftTimeTooLong = 'CraftTimeTooLong',
    LackLongCraft = 'LackLongCraft',
    SpaceConflict = 'SpaceConflict',
    TooManyCraftForOneVehicle = 'TooManyCraftForOneVehicle',
}

export class TrackSuggestion
{
    public level: SuggestionLevel;
    public type: SuggestionType;
    public title: string;
    public message: string;

    public constructor(init?: Partial<TrackSuggestion>) { (Object as any).assign(this, init); }
}

export class MovingTrack extends TrackBase
{
    public startPosition: number;
    public endPosition: number;
    public suggestions: TrackSuggestion[];

    public constructor(init?: Partial<MovingTrack>) { super(init); (Object as any).assign(this, init); }
}

export class CraftPlan
{
    public position: number;
    public executeTime: number;
    public startTime: number;
    public endTime: number;

    public constructor(init?: Partial<CraftPlan>) { (Object as any).assign(this, init); }
}

export class Plan
{
    public movingTracks: MovingTrack[];
    public lineCrafts: CraftPlan[];
    public suggestions: TrackSuggestion[];

    public constructor(init?: Partial<Plan>) { (Object as any).assign(this, init); }
}

export enum TrackMoveType
{
    MoveUp = 'MoveUp',
    MoveUpToMiddle = 'MoveUpToMiddle',
    MoveDown = 'MoveDown',
    MoveNext = 'MoveNext',
    MovePrevious = 'MovePrevious',
    Waiting = 'Waiting',
    Tentative = 'Tentative',
}

export enum VerticalPosition
{
    Top = 'Top',
    Bottom = 'Bottom',
    Middle = 'Middle',
}

export class TrackMove
{
    public time: number;
    public until: number;
    public moveType: TrackMoveType;
    public startPosition: number;
    public horizontalTarget: number;
    public verticalTarget: VerticalPosition;

    public constructor(init?: Partial<TrackMove>) { (Object as any).assign(this, init); }
}

export enum PointType
{
    None = 'None',
    StartMovingUp = 'StartMovingUp',
    MovingUp = 'MovingUp',
    StartMovingDown = 'StartMovingDown',
    MovingDown = 'MovingDown',
    MovedToTop = 'MovedToTop',
    MovedToMiddle = 'MovedToMiddle',
    MovedToBottom = 'MovedToBottom',
    MovingHorizontal = 'MovingHorizontal',
    StartMovingHorizontal = 'StartMovingHorizontal',
    MovedHorizontalToTarget = 'MovedHorizontalToTarget',
}

export class TrackPoint
{
    public time: number;
    public slotPosition?: number;
    public pointType: PointType;

    public constructor(init?: Partial<TrackPoint>) { (Object as any).assign(this, init); }
}

export class VehicleTrack
{
    public vehicleIndex: number;
    public startPosition: number;
    public startCraftIndex: number;
    public endCraftIndex: number;
    public startTime: number;
    public currentTime: number;
    public currentPosition: number;
    public emptyMoved: number;
    public withWorkpiece: boolean;
    public finishedTrack: boolean;
    public moves: TrackMove[];
    public movePoints: TrackPoint[];
    public points: TrackPoint[];

    public constructor(init?: Partial<VehicleTrack>) { (Object as any).assign(this, init); }
}

export enum NotificationType
{
    Info = 'Info',
    Warn = 'Warn',
    Error = 'Error',
}

export class Notification
{
    public title: string;
    public message?: string;
    public type: NotificationType;

    public constructor(init?: Partial<Notification>) { (Object as any).assign(this, init); }
}

export class ScheduleResponse extends Plan implements IResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ScheduleResponse>) { super(init); (Object as any).assign(this, init); }
}

export class Schedule extends SchedulingContextBase implements IReturn<ScheduleResponse>
{

    public constructor(init?: Partial<Schedule>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'Schedule'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScheduleResponse(); }
}

export class CraftsResponse extends ResponseBase
{
    public schedule: Schedule;

    public constructor(init?: Partial<CraftsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class GetAllProjectsResponse extends ResponseBase
{
    public projects: ArrayOfProject;

    public constructor(init?: Partial<GetAllProjectsResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CraftParameterModel
{
    public projectName: string;
    public cycleTime: number;
    public craftCount: number;
    public crafts: Craft[];

    public constructor(init?: Partial<CraftParameterModel>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CraftParameterModel'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

export class ManageProjectResponse extends ResponseBase
{
    public name: string;
    public craftParameterModel: CraftParameterModel;
    public projectPhase: ProjectPhase;

    public constructor(init?: Partial<ManageProjectResponse>) { super(init); (Object as any).assign(this, init); }
}

export class CraftOperateResult
{
    public crafts: Craft[];

    public constructor(init?: Partial<CraftOperateResult>) { (Object as any).assign(this, init); }
}

export class VehicleOperateResult
{
    public vehicleIndex: number;
    public currentTime: number;
    public currentPosition: number;
    public moveType: VehicleMoveType;
    public trackMoves?: TrackMove[];
    public trackPoints?: TrackPoint[];
    public emptyMoved: number;
    public startPosition: number;
    public startTime: number;
    public finishedTrack: boolean;
    public currentTrackCount: number;
    public crafts?: Craft[];
    public message?: string;

    public constructor(init?: Partial<VehicleOperateResult>) { (Object as any).assign(this, init); }
}

export class VehicleAutoOperateResult
{
    public vehicleTracks: VehicleTrack[];
    public slots: Slot[];
    public crafts: Craft[];
    public success: boolean;
    public notifications: Notification[];

    public constructor(init?: Partial<VehicleAutoOperateResult>) { (Object as any).assign(this, init); }
}

export class ScheduleParameters
{
    public currentCraft: number;
    public horizontalDistance: number;
    public horizontalMoveTime: number;
    public horizontalSpeed: number;
    public verticalDistance: number;
    public verticalMoveTime: number;
    public verticalShortMoveTime: number;
    public verticalSpeed: number;

    public constructor(init?: Partial<ScheduleParameters>) { (Object as any).assign(this, init); }
}

export class RetreiveOperateResult
{
    public projectName: string;
    public trackMoves: TrackMove[];
    public vehicleTracks: VehicleTrack[];
    public crafts: Craft[];
    public slots: Slot[];
    public scheduleParameters: ScheduleParameters;

    public constructor(init?: Partial<RetreiveOperateResult>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegisterResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public referrerUrl: string;

    // @DataMember(Order=5)
    public bearerToken: string;

    // @DataMember(Order=6)
    public refreshToken: string;

    // @DataMember(Order=7)
    public roles: string[];

    // @DataMember(Order=8)
    public permissions: string[];

    // @DataMember(Order=9)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=10)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegisterResponse>) { (Object as any).assign(this, init); }
}

export class Crafts implements IReturn<CraftsResponse>
{
    public projectName: string;
    public schedule: Schedule;

    public constructor(init?: Partial<Crafts>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Crafts'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CraftsResponse(); }
}

// @DataContract
export class GetAllProjects implements IReturn<GetAllProjectsResponse>
{
    // @DataMember
    public creator: string;

    public constructor(init?: Partial<GetAllProjects>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetAllProjects'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetAllProjectsResponse(); }
}

// @Route("/projects")
// @Route("/projects/{Name}")
export class ManageProject implements IReturn<ManageProjectResponse>
{
    public name: string;
    public creator: string;
    public projectPhase: ProjectPhase;

    public constructor(init?: Partial<ManageProject>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ManageProject'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ManageProjectResponse(); }
}

export class CraftOperate extends OperateBase implements IReturn<CraftOperateResult>
{
    public crafts: Craft[];

    public constructor(init?: Partial<CraftOperate>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'CraftOperate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CraftOperateResult(); }
}

export class VehicleOperate extends OperateBase implements IReturn<VehicleOperateResult>
{
    public vehicleIndex: number;
    public startTime: number;
    public endTime: number;
    public horizontalOffset: number;
    public specificMoveTime: number;
    public vehicleMoveType: VehicleMoveType;

    public constructor(init?: Partial<VehicleOperate>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'VehicleOperate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new VehicleOperateResult(); }
}

export class VehicleAutoOperate extends OperateBase implements IReturn<VehicleAutoOperateResult>
{
    public type: VehicleAutoOperateType;

    public constructor(init?: Partial<VehicleAutoOperate>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'VehicleAutoOperate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new VehicleAutoOperateResult(); }
}

export class RetreiveOperate extends OperateBase implements IReturn<RetreiveOperateResult>
{

    public constructor(init?: Partial<RetreiveOperate>) { super(init); (Object as any).assign(this, init); }
    public getTypeName() { return 'RetreiveOperate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RetreiveOperateResult(); }
}

export class UpdateScheduleParam
{
    public projectName: string;
    public scheduleParameters: ScheduleParameters;

    public constructor(init?: Partial<UpdateScheduleParam>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UpdateScheduleParam'; }
    public getMethod() { return 'PUT'; }
    public createResponse() {}
}

export class GetScheduleParam implements IReturn<ScheduleParameters>
{
    public projectName: string;

    public constructor(init?: Partial<GetScheduleParam>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetScheduleParam'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScheduleParameters(); }
}

/** @description Sign In */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /** @description AuthProvider, e.g. credentials */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

/** @description Sign Up */
// @Route("/register", "PUT,POST")
// @Api(Description="Sign Up")
// @DataContract
export class Register implements IReturn<RegisterResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public firstName: string;

    // @DataMember(Order=3)
    public lastName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public email: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public confirmPassword: string;

    // @DataMember(Order=8)
    public autoLogin?: boolean;

    // @DataMember(Order=10)
    public errorView: string;

    // @DataMember(Order=11)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Register>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Register'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegisterResponse(); }
}

