
import { Component } from "vue-property-decorator";
import Vue from "vue";
import { projectInstance } from "@/shared/project-instance";

@Component
export class ScheduleContext extends Vue {

  protected crafts = projectInstance.projectState.schedule.crafts;
  protected contextVisible: boolean = false;
}
export default ScheduleContext;
