
import {Component, Vue} from "vue-property-decorator";
import {projectInstance} from "@/shared/project-instance";
import {Craft, PeriodType, ProjectPhase} from "@/shared/dtos";
import {router, Routes} from "@/shared/router";
import CraftService from "@/services/CraftService";
import ProjectService from "@/services/ProjectService";
import ScheduleParametersForm from "@/components/Studio/Configuration/ScheduleParametersForm.vue";
import ScheduleService from "@/services/ScheduleService";
import CloneCraftsForm from "@/components/Studio/Configuration/CloneCraftsForm.vue";

@Component({
  components: {CloneCraftsForm, ScheduleParametersForm}
})
export default class Configuration extends Vue {
  get cycleTime() {
    return projectInstance.projectState.cycleTime;
  }

  set cycleTime(val) {
    projectInstance.projectState.cycleTime = val;
  }

  protected periodTypes: any[] = Object.keys(PeriodType).map(key => {
    const periodKeyToLabel = {
      Upload: '上件',
      Download: '转挂下件',
      Normal: '普通',
      FurnaceImport: '烘干炉进口',
      FurnaceOutlet: '烘干炉出口',
    };
    // @ts-ignore
    const label = periodKeyToLabel[key];
    return ({label: label, value: key});
  });

  protected scheduleParameterVisible: boolean = false;
  protected cloneCraftsVisible: boolean = false;

  protected crafts: Craft[] = projectInstance.projectState.crafts;

  protected selection: any = [];
  public rightClickIndex: number = 0;

  protected menuItems: any[] = [
    {
      label:'新建工艺',
      icon:'pi pi-fw pi-plus',
      items:[
        {
          label:'在该工艺之前',
          icon:'pi pi-fw pi-caret-up',
          command: () => {
            this.insertCraft(this.getRightClickIndex());
          }
        },
        {
          label:'在该工艺之后',
          icon:'pi pi-fw pi-caret-down',
          command: () => {
            this.insertCraft(this.getRightClickIndex() + 1);
          }
        },

      ]
    },
    {
      label:'删除',
      icon:'pi pi-fw pi-trash',
      command: () => {
        this.removeCraft(this.getRightClickIndex());
      }
    }
  ];

  protected onItemRightClick(props: any, event: any) {
    this.rightClickIndex = props.index;
    // @ts-ignore
    this.$refs.menu && this.$refs.menu.show(event);
  }

  protected addCraft(): void {
    this.crafts.push(new Craft({
      name: "",
      periodType: PeriodType.Normal,
      minTime: 60
    }));
  }

  protected insertCraft(insertIndex: number): void {
    this.crafts.splice(insertIndex, 0, new Craft({
      name: "",
      periodType: PeriodType.Normal,
      minTime: 60
    }));
  }

  protected clickRemoveBtn(index: number, event: any): void {
    this.$confirm.require({
      target: event.currentTarget,
      message: '是否删除该工艺？',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.removeCraft(index);
      },
    });
  }

  protected removeCraft(index: number) {
    this.crafts.splice(index, 1);
    this.$toast.add({severity: 'success', summary: '操作成功', detail: `已删除工艺${index + 1}`, life: 3000});
  }

  public getRightClickIndex(): number {
    return this.rightClickIndex;
  }

  protected saveCrafts() {
    CraftService.putAll(projectInstance.projectState.name, projectInstance.projectState.cycleTime, projectInstance.projectState.crafts).then(() => {
      this.$toast.add({severity: 'info', summary: '保存成功', detail: `已保存当前工艺数据`, life: 3000});
    });
  }

  protected startCalc() {
    CraftService.putAll(projectInstance.projectState.name, projectInstance.projectState.cycleTime, projectInstance.projectState.crafts).then(() => {
      ProjectService.updatePhase(projectInstance.projectState.name, ProjectPhase.Scheduling).then((result) => {
        if (result.responseStatus && result.responseStatus.errorCode === "20001003") {
          this.$toast.add({severity: 'error', summary: '缺少参数！', detail: '请点击参数配置并配置行车运动参数，再进行计算', life: 4000});
          return;
        }
        projectInstance.$set(projectInstance.projectState, "phase", ProjectPhase.Scheduling);
        ScheduleService.retrieveSchedule(projectInstance.projectState.name).then((task) => {
          projectInstance.projectState.slots = task.slots;
          projectInstance.projectState.scheduleParameters = task.scheduleParameters;
          projectInstance.projectState.vehicleTracks = task.vehicleTracks;
          router.push({path: `${Routes.Studio}/${Routes.SchedulePanel}`});
        });
      });
    });
  }

  protected saveScheduleParams() {
    // @ts-ignore
    this.$refs.scheduleParam.save();
  }

  protected scheduleParamsSaved() {
    this.$toast.add({severity: 'success', summary: '操作成功', detail: `已保存运动参数`, life: 3000});
  }
}
