
import Vue from "vue";
import {Component} from "vue-property-decorator";
import {projectInstance} from "@/shared/project-instance";
import {bus} from "@/shared";
import Events from "@/constants/Events";
import {ProjectPhase} from "@/shared/dtos";
import {router} from "@/shared/router";

@Component
export class TopToolbar extends Vue {
  get projectName() {
    return projectInstance.projectState.name;
  }

  get phase() {
    return projectInstance.projectState.phase;
  }

  public items: any[] = [
    {
      label: "ICTLabo",
      class: "menubar-root",
    },
    {
      label: "文件",
      items: [
        {
          label: "新建",
          icon: "pi pi-fw pi-plus",
          items: [
            {
              label: "新建工程",
              icon: "pi pi-fw pi-briefcase",
              command: () => {
                bus.$emit(Events.NEW_PROJECT);
              }
            },
            {
              label: "新建工艺配置",
              icon: "pi pi-fw pi-video",
              disabled: () => {
                return this.phase === ProjectPhase.CraftEntry;
              },
            },
          ],
        },
        {
          label: "打开工程",
          icon: "pi pi-fw pi-folder-open",
          command: () => {
            bus.$emit(Events.OPEN_PROJECT);
          }
        },
        {
          separator: true,
        },
        {
          label: "保存工程",
          icon: "pi pi-fw pi-save",
          command: () => {
            bus.$emit(Events.SAVE_PROJECT);
          }
        },
        {
          label: "导入",
          icon: "pi pi-fw pi-file-import",
          items: [
            {
              label: "导入工程",
              disabled: true
            },
            {
              label: "导入工艺配置",
              disabled: () => {
                return this.phase === ProjectPhase.ProjectNotInit;
              },
              command: () => {
                bus.$emit(Events.IMPORT_CRAFT_PARAMETERS);
              }
            }
          ]
        },
        {
          label: "导出",
          icon: "pi pi-fw pi-external-link",
          items: [
            {
              label: "导出工程",
            },
            {
              label: "导出工艺配置",
            }
          ]
        },
      ],
    },
    // {
    //   label: "编辑",
    //   items: [
    //     {
    //       label: "调整工艺",
    //       icon: "pi pi-fw pi-align-left",
    //     },
    //     {
    //       label: "Right",
    //       icon: "pi pi-fw pi-align-right",
    //     },
    //     {
    //       label: "Center",
    //       icon: "pi pi-fw pi-align-center",
    //     },
    //     {
    //       label: "Justify",
    //       icon: "pi pi-fw pi-align-justify",
    //     },
    //   ],
    // },
    {
      label: "用户",
      items: [
        {
          label: "留言",
          icon: "pi pi-fw pi-info-circle",
        },
      ],
    },
    {
      label: "退出",
      command: () => {
        router.push({path: "/"});
      }
    },
  ];

  mounted() {
    bus.$on(Events.NEW_PROJECT_CREATED, (val: string) => {
      this.$forceUpdate();
    });
  }
}

export default TopToolbar;
