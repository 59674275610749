/**
 * @file PointTypeUtils.ts
 * @description 点类型转换工具类
 * @author Riven
 * @date 2023-04-24
 * @version V1.0.0
 */
import {PointType} from "@/shared/dtos";

/**
 * 根据type返回颜色
 *
 * @param type
 * @returns {string} 颜色
 */
function typeToColor(type: string): string {
    switch (type) {
        case PointType.StartMovingUp:
        case PointType.StartMovingDown:
        case PointType.StartMovingHorizontal:
            return "#00ff15";
        case PointType.MovedToTop:
            return "#ff4d00";
        case PointType.MovedToMiddle:
            return "#e800e7";
        case PointType.MovedToBottom:
            return "#ff4d00";
        case PointType.None:
            return "#0000FF";
        default:
            return "#0000FF";
    }
}

/**
 * 根据type返回文字
 *
 * @param type
 * @returns {string} 文字
 */
function typeToText(type: string): string {
    switch (type) {
        case PointType.StartMovingUp:
            return "行车开始上升";
        case PointType.MovedToTop:
            return "行车处于高位";
        case PointType.MovedToMiddle:
            return "行车处于中位";
        case PointType.StartMovingDown:
            return "行车开始下降";
        case PointType.MovedToBottom:
            return "行车处于低位";
        case PointType.StartMovingHorizontal:
            return "行车开始水平移动";
        case PointType.MovedHorizontalToTarget:
            return "行车水平移动到目标槽位";
        case PointType.None:
            return "行车无动作";
        default:
            return "行车无动作";
    }
}

/**
 * 根据type返回旋转角度
 *
 * @param type
 * @returns {number} 旋转角度
 */
function typeToRotate(type: string): number {
    switch (type) {
        case PointType.StartMovingUp:
            return 0;
        case PointType.MovedToTop:
            return 0;
        case PointType.StartMovingDown:
        case PointType.MovingDown:
            return 180;
        case PointType.MovedToBottom:
            return 180;
        case PointType.StartMovingHorizontal:
            return 90;
        case PointType.MovedHorizontalToTarget:
            return 90;
        case PointType.None:
            return 0;
        default:
            return 0;
    }
}

/**
 * 根据type返回symbol
 *
 * @param type
 * @returns {string} symbol
 */
function typeToSymbol(type: string): string {
    switch (type) {
        case PointType.StartMovingUp:
        case PointType.StartMovingDown:
            return "emptyTriangle";
        case PointType.MovedToTop:
            return "triangle";
        case PointType.MovedToMiddle:
                return "triangle";
        case PointType.StartMovingHorizontal:
            return "emptyCircle";
        case PointType.MovedHorizontalToTarget:
            return "circle";
        case PointType.None:
            return "emptyCircle";
        default:
            return "emptyCircle";
    }
}

/**
 * 根据type返回size
 * @param type
 * @returns {number} size
 */
function typeToSize(type: string): number {
    switch (type) {
        case PointType.StartMovingUp:
        case PointType.StartMovingDown:
        case PointType.MovedToTop:
        case PointType.MovedToMiddle:
            return 15;
        case PointType.StartMovingHorizontal:
            return 5;
        case PointType.MovedHorizontalToTarget:
            return 5;
        case PointType.None:
        default:
            return 5;
    }
}

export { typeToColor, typeToText, typeToRotate, typeToSymbol, typeToSize };
