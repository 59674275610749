
import {Component, Vue} from 'vue-property-decorator';
import {DomHandler} from "primevue/utils";
import {Notification, NotificationType} from "@/shared/dtos";
import NotificationEventBus from "@/common/notification/NotificationEventBus";

@Component({})
export default class NotificationDialog extends Vue {
  private visible: boolean = false;
  private header: string = '提示';
  private position: string = 'center';
  protected content: Notification[] = [];
  protected conclusion: string = '';

  mounted() {
    NotificationEventBus.on('notify', (options: Notification[]) => {
      if (!options) {
        return;
      }
      this.content = options;
      if (this.content.filter((n) => n.type === NotificationType.Error).length > 0) {
        this.conclusion = '规划未完成，存在错误';
      } else if (this.content.filter((n) => n.type === NotificationType.Warn).length > 0) {
        this.conclusion = '规划完成，存在警告';
      } else {
        this.conclusion = '规划完成';
      }
      this.visible = true;
    });
    NotificationEventBus.on('close', () => {
      this.visible = false;
      this.content = [];
    });
  }
  beforeDestroy() {
    NotificationEventBus.off('notify');
    NotificationEventBus.off('close');
  }

  protected closeDialog($event: any) {
    if(DomHandler.hasClass($event.target, 'p-dialog-header-close') || DomHandler.hasClass($event.target, 'p-dialog-header-close-icon')) {
      NotificationEventBus.off('notify');
      NotificationEventBus.off('close');
      this.visible = false;
    }
  }
}
