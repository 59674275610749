
import {Component, Vue} from "vue-property-decorator";
import MovesDock from "@/components/Studio/ScheduleContext/MovesDock.vue";
import ScheduleCraftsDock from "@/components/Studio/ScheduleContext/ScheduleCraftsDock.vue";

@Component({
  components: {ScheduleCraftsDock, MovesDock}
})
export default class ScheduleContextPanel extends Vue {

}
