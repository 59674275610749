import Vue from 'vue';
import {
    Craft,
    ProjectPhase,
    Schedule,
    ScheduleParameters,
    ScheduleResponse,
    Slot,
    TrackPoint,
    VehicleTrack
} from "@/shared/dtos";

export enum Phase {
    ProjectNotInit,
    NewProject,
    CraftEntry,
    CraftDetailConfig,
    InitialCalculation
}

interface ProjectState {
    name: string;
    status: string;
    phase: ProjectPhase;
    schedule: Schedule;
    cycleTime: number;
    craftCount: number;
    crafts: Craft[];
    slots: Slot[];
    currentCraft: number;
    vehicleTracks: VehicleTrack[];
    scheduleParameters: ScheduleParameters;
}

const projectState: ProjectState = {
    name: "",
    status: "",
    phase: ProjectPhase.ProjectNotInit,
    schedule: new Schedule(),
    cycleTime: 300,
    craftCount: 0,
    crafts: [],
    slots: [],
    currentCraft: 1,
    vehicleTracks: [],
    scheduleParameters: new ScheduleParameters(),
}

class ProjectInstance extends Vue {
    public projectState: ProjectState = projectState;

    public preliminaryResults: ScheduleResponse = new ScheduleResponse();
}

export let projectInstance = new ProjectInstance({ data: projectState });

export function initProjectInstance() {
    const state: ProjectState = {
        name: "",
        status: "",
        phase: ProjectPhase.ProjectNotInit,
        schedule: new Schedule(),
        cycleTime: 300,
        craftCount: 0,
        crafts: [],
        slots: [],
        currentCraft: 1,
        vehicleTracks: [],
        scheduleParameters: new ScheduleParameters(),
    }
    projectInstance = new ProjectInstance({ data: state });
}

projectInstance.$set(projectInstance.projectState.schedule, "crafts", []);
projectInstance.$set(projectInstance.projectState.schedule, "cycleTime", 900);
