
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { store } from "@/shared";

const navItems = [
  { href: "/", label: "首页", exact: true },
  { href: "/about", label: "关于" },
  // { href: "/signin", label: "登录", hide: "auth" },
  // { href: "/profile", label: "配置", show: "auth" },
  // { href: "/studio", label: "操作台" },
  // { href: "/admin", label: "管理", show: "role:Admin" },
];

@Component
export class Home extends Vue {
  get store() {
    return store;
  }
  get navItems() {
    return navItems;
  }
}
export default Home;
