
import { Vue, Component, Prop } from "vue-property-decorator";
import { store, signout } from "@/shared";

@Component
export class Welcome extends Vue {
  @Prop({ default: "" }) name: string;
  signout = signout;

  get userSession() {
    return store.userSession;
  }
}
export default Welcome;
