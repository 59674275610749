import Vue from 'vue';
import Router, {Route} from 'vue-router';

import {bus, store} from './index';

import {Forbidden} from '@servicestack/vue';
import Home from "@/components/Home.vue";
import Welcome from '../components/Welcome/index.vue';
import About from '../components/About.vue';
import SignIn from '../components/SignIn.vue';
import SignUp from '../components/SignUp.vue';
import Profile from '../components/Profile.vue';
import Admin from '../components/Admin/index.vue';
import Studio from "../components/Studio/index.vue";
import ScheduleContext from "@/components/Studio/ScheduleContext/index.vue";
import Configuration from "@/components/Studio/Configuration/index.vue";
import {projectInstance} from "@/shared/project-instance";
import {ProjectPhase} from "@/shared/dtos";
import PreliminaryResults from "@/components/Studio/ResultPanel/PreliminaryResults.vue";
import HelpPanel from "@/components/Studio/ScheduleContext/HelpPanel.vue";
import NotInitHelpPanel from "@/components/Studio/ScheduleContext/NotInitHelpPanel.vue";
import ResultSuggestions from "@/components/Studio/ScheduleContext/ResultSuggestions.vue";
import SchedulePanel from "@/components/Studio/SchedulePanel/index.vue";
import ScheduleCraftsDock from "@/components/Studio/ScheduleContext/ScheduleCraftsDock.vue";
import ScheduleContextPanel from "@/components/Studio/ScheduleContext/ScheduleContextPanel.vue";

export enum Routes {
  Home = '/',
  Welcome = 'welcome',
  About = 'about',
  SignIn = 'signin',
  SignUp = 'signup',
  Profile = '/profile',
  Admin = '/admin',
  Forbidden = '/forbidden',
  Studio = '/studio',
  Console = 'console',
  Configure = 'configure',
  ResultPanel = 'result-panel',
  SchedulePanel = 'schedule-panel'
}

Vue.use(Router);

function requiresAuth(to: Route, from: Route, next: (to?: string) => void) {
  if (!store.userSession) {
    next(`${Routes.SignIn}?redirect=${encodeURIComponent(to.path)}`);
    return;
  }
  next();
}

function requiresRole(role: string) {
  return (to: Route, from: Route, next: (to?: string) => void) => {
    if (!store.userSession) {
      next(`${Routes.SignIn}?redirect=${encodeURIComponent(to.path)}`);
    }
    else if (!store.userSession.roles || store.userSession.roles.indexOf(role) < 0) {
      next(`${Routes.Forbidden}?role=${encodeURIComponent(role)}`);
    }
    else {
      next();
    }
  };
}

function requiresProject(to: Route, from: Route, next: (to?: string) => void) {
  if (projectInstance.projectState.phase === ProjectPhase.ProjectNotInit) {
    next(Routes.Studio);
  } else if (to.path === `${Routes.Studio}/${Routes.ResultPanel}` && projectInstance.projectState.phase === ProjectPhase.CraftEntry) {
    next(`${Routes.Studio}/${Routes.Configure}`);
  } else {
    next();
  }
}

const routes = [
  {
    path: Routes.Home, component: Home, props: { name: 'Vue' },
    children: [
      { path: "", component: Welcome },
      { path: Routes.About, component: About, props: { message: 'About page' } },
      { path: Routes.SignIn, component: SignIn },
      { path: Routes.SignUp, component: SignUp },
      { path: Routes.Profile, component: Profile, beforeEnter: requiresAuth },
      { path: Routes.Admin, component: Admin, beforeEnter: requiresRole('Admin') },
    ]
  },
  { path: Routes.Forbidden, component: Forbidden },
  {
    path: Routes.Studio, component: Studio, beforeEnter: requiresAuth,
    children: [
      { path: "", components: { left: NotInitHelpPanel } }, // 未初始化
      { path: Routes.Configure, components: { main: Configuration, left: HelpPanel }, beforeEnter: requiresProject }, // 配置工艺
      { path: Routes.SchedulePanel, components: { main: SchedulePanel, left: ScheduleContextPanel, config: ScheduleContext }, beforeEnter: requiresProject }, // 规划中
      { path: Routes.ResultPanel, components: { main: PreliminaryResults, left: ResultSuggestions, config: ScheduleContext }, beforeEnter: requiresProject },
    ]
  },
  { path: '*', redirect: '/' },
];

export const router = new Router ({
    mode: 'history',
    linkActiveClass: 'active',
    routes,
});

export const redirect = (path: string) => {
  const externalUrl = path.indexOf('://') >= 0;
  if (!externalUrl) {
      router.push({ path });
  } else {
      location.href = path;
  }
};

bus.$on('signout', async () => {
  // reload current page after and run route guards after signing out.
  const to = router.currentRoute;
  router.replace('/');
  router.replace(to.fullPath);
});
