
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import * as echarts from "echarts/core";
import {
  TitleComponent,
  TitleComponentOption,
  ToolboxComponent,
  ToolboxComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption,
  LegendComponent,
  LegendComponentOption,
  GraphicComponent,
} from "echarts/components";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import {bus} from "@/shared";
import Events from "@/constants/Events";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition,
  GraphicComponent,
]);

type EChartsOption = echarts.ComposeOption<
  | TitleComponentOption
  | ToolboxComponentOption
  | TooltipComponentOption
  | GridComponentOption
  | LegendComponentOption
  | LineSeriesOption
>;

@Component
export class SingleChart extends Vue {
  @Prop({ default: null }) chartOption: EChartsOption;

  @Prop({ default: null }) id: string;

  protected showPrevious: boolean = false;

  public chart: echarts.ECharts;

  public option: EChartsOption = {};

  public mounted() {
    var chartDom = document.getElementById(this.id)!;
    this.chart = echarts.init(chartDom);
    this.option = {
      tooltip: {
        trigger: "axis",
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      toolbox: {
        feature: {
          saveAsImage: {},
        },
      },
      xAxis: {
        type: "value",
        min: 0,
      },
      yAxis: {
        type: "value",
        inverse: true,
      },
    };

    this.option && this.chart.setOption(this.option);

    if (this.chartOption) {
      this.chart.setOption(this.chartOption);
    }

    this.registerEvents();
  }

  @Watch("chartOption")
  public onDataChanged(value: EChartsOption) {
    this.handleResultData(value);
  }

  registerEvents() :void {
    this.$on(Events.REFRESH_CHART, () => {
      this.chart.setOption(this.option);
    });
  }

  public handleResultData(option: EChartsOption) {
    this.chart.setOption(option);
  }

  public switchChanged() {
    if (this.showPrevious) {
      bus.$emit(Events.SHOW_PREVIOUS_DATA, this.id);
    }
  }
}
export default SingleChart;
