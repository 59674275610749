
import {Component, Vue} from "vue-property-decorator";
import {projectInstance} from "@/shared/project-instance";
import {bus} from "@/shared";
import Events from "@/constants/Events";
import ScheduleService from "@/services/ScheduleService";
import {PeriodType} from "@/shared/dtos";

@Component
export default class ScheduleCraftsDock extends Vue {
  protected periodTypes: any[] = Object.keys(PeriodType).map(key => {
    const periodKeyToLabel = {
      Upload: '上件',
      Download: '转挂下件',
      Normal: '普通',
      FurnaceImport: '烘干炉进口',
      FurnaceOutlet: '烘干炉出口',
    };
    // @ts-ignore
    const label = periodKeyToLabel[key];
    return ({label: label, value: key});
  });
  get crafts() {
    const crafts: any = [];
    projectInstance.projectState.crafts.forEach((craft) => {
      const cycleTime = projectInstance.projectState.cycleTime;
      const startTime: number = craft.startTime % cycleTime;
      const endTime: number = craft.endTime === cycleTime ? craft.endTime : craft.endTime % cycleTime;
      let startTimeStr: string = `${startTime} 秒`;
      let endTimeStr: string = `${endTime} 秒`;
      if (startTime > endTime) {
        startTimeStr = `${startTime} 秒`;
        endTimeStr = `${endTime} 秒(下周期)`;
      }
      const item = {
        name: craft.name,
        minTime: `${craft.minTime}`,
        startTime: startTimeStr,
        endTime: endTimeStr,
      }
      crafts.push(item);
    });
    return crafts;
  }

  get currentIndex() {
    return projectInstance.projectState.currentCraft;
  }

  get craftTime() {
    return projectInstance.projectState.crafts[this.editingIndex].minTime;
  }

  set craftTime(val) {
    projectInstance.projectState.crafts[this.editingIndex].minTime = val;
  }

  get periodType() {
    return projectInstance.projectState.crafts[this.editingIndex].periodType;
  }

  set periodType(val) {
    projectInstance.projectState.crafts[this.editingIndex].periodType = val;
  }

  protected visible: boolean = false;

  protected editingIndex: number = 0;

  edit(index: number): void {
    this.editingIndex = index;
    this.visible = true;
  }

  doEdit() {
    ScheduleService.editCraft(projectInstance.projectState.name, projectInstance.projectState.crafts).then((result) => {
      if (result.crafts && result.crafts.length > 0) {
        projectInstance.projectState.crafts = result.crafts;
        bus.$emit(Events.CRAFTS_MODIFIED);
        this.visible = false;
      }
    });
  }

  addToSchedule(index: number): void {
    bus.$emit(Events.ADD_CRAFT_TO_CHART, index);
  }
}
