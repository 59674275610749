/**
 * 事件常量
 */
export default {
    // 项目管理相关事件
    NEW_PROJECT: "NewProject",
    NEW_PROJECT_CREATED: "NewProjectCreated",
    OPEN_PROJECT: "OpenProject",
    IMPORT_CRAFT_PARAMETERS: "ImportCraftParameters",
    SAVE_PROJECT: "SaveProject",

    // 计算相关事件
    SHOW_PREVIOUS_DATA: "ShowPreviousData",
    REFRESH_CHART: "RefreshChart",
    ADD_CRAFT_TO_CHART: "AddCraftToChart",
    CHANGE_VEHICLE_INDEX: "ChangeVehicleIndex",
    ROLLBACK_MOVE: "RollbackMove",
    CRAFTS_MODIFIED: "CraftsModified",
}
