
import {Component, Vue} from "vue-property-decorator";
import SingleChartsCollection from "@/components/Charts/SingleChartsCollection.vue";
import {projectInstance} from "@/shared/project-instance";
import {ProjectPhase} from "@/shared/dtos";
import {router, Routes} from "@/shared/router";

@Component({
  components: {
    SingleChartsCollection,
  },
})
export default class PreliminaryResults extends Vue {
  get result() {
    return projectInstance.preliminaryResults;
  }

  get crafts() {
    return projectInstance.projectState.schedule.crafts;
  }

  get cycleTime() {
    return projectInstance.projectState.schedule.cycleTime;
  }

  protected backToCraftConfigure() {
    projectInstance.$set(projectInstance.projectState, "phase", ProjectPhase.CraftEntry);
    router.push({path: `${Routes.Studio}/${Routes.Configure}`});
  }
}
