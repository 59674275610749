
import {Component, Vue} from "vue-property-decorator";

import * as echarts from 'echarts/core';
import {
  DataZoomComponent,
  GraphicComponent,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  VisualMapComponent
} from 'echarts/components';
import {LineChart, LineSeriesOption} from 'echarts/charts';
import {UniversalTransition} from 'echarts/features';
import {CanvasRenderer} from 'echarts/renderers';
import {projectInstance} from "@/shared/project-instance";
import Events from "@/constants/Events";
import ScheduleService from "@/services/ScheduleService";
import {SlotType, TrackPoint, VehicleAutoOperateResult, VehicleMoveType, VehicleOperateResult} from "@/shared/dtos";
import {typeToColor, typeToRotate, typeToSize, typeToSymbol, typeToText} from "@/utils/PointTypeUtils";
import WaitConfigureForm from "@/components/Studio/SchedulePanel/WaitConfigureForm.vue";
import {bus} from "@/shared";
import ScheduleParametersForm from "@/components/Studio/Configuration/ScheduleParametersForm.vue";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  GraphicComponent,
  VisualMapComponent,
  LineChart,
  CanvasRenderer,
  UniversalTransition
]);

@Component({
  components: {ScheduleParametersForm, WaitConfigureForm}
})
export default class ScheduleChart extends Vue {
  public chart: echarts.ECharts;

  public vehicleIndex: number = 0;
  public currentTime: number = 0;
  public currentPosition: number = 0;
  public moveType: VehicleMoveType = VehicleMoveType.None;
  public showStepMoves: boolean = false;
  public graphicOptions: any[] = [];

  get vehicles() {
    return projectInstance.projectState.vehicleTracks.map((vehicle, index) => {
      return {name: `行车${index + 1}`, code: index};
    });
  }

  public moveHorizontalTarget: number = 0;

  get slots() {
    return projectInstance.projectState.slots.map((slot, index) => {
      return {name: `工位${index + 1}`, code: index};
    });
  }

  public loading: boolean = false;

  protected targetSelectVisible: boolean = false;

  protected waitDialogVisible: boolean = false;

  protected craftsGraphicVisible: boolean = true;

  protected rollbackVisible: boolean = false;

  protected rollbackCount: number = 0;

  protected horizontalButtonItems: any[] = [
    {label: '上一工位', icon: 'pi pi-arrow-left', command: () => this.moveHorizontal('Previous')},
    {label: '下一工位', icon: 'pi pi-arrow-right', command: () => this.moveHorizontal('Next')},
    {label: '指定工位', icon: 'pi pi-sort-numeric-down-alt', command: () => this.showTargetSelect()},
  ];

  protected submitWait() {
    // @ts-ignore
    this.vehicleWait(this.$refs.waitForm.waitTime);
    this.waitDialogVisible = false;
  }

  protected showTargetSelect() {
    this.targetSelectVisible = true;
  }

  protected onChangeVehicle(evt: any) {
    bus.$emit(Events.CHANGE_VEHICLE_INDEX, evt.value);
  }

  protected toggleViewMode() {
    this.chart.setOption({
      xAxis: {
        max: undefined,
      },
    });
  }

  protected showScheduleParamForm(event: any) {
    // @ts-ignore
    this.$refs.op.toggle(event);
  }

  protected addNewVehicle() {
    const vehicleIndex = projectInstance.projectState.vehicleTracks.length;
    ScheduleService.addNewVehicle(projectInstance.projectState.name, vehicleIndex, 0).then((result) => {
      this.updateOrCreateSeries(result);
    });
  }

  protected generateAllTrack() {
    this.loading = true;
    ScheduleService.generateAllTrack(projectInstance.projectState.name).then((result) => {
      this.updateAllSeries(result);
      this.loading = false;
      if (result.notifications && result.notifications.length > 0) {
        this.$notify.require(result.notifications);
      } else if (result.success) {
        this.$confirm.require({
          header: '规划结束',
          message: `已完成所有工艺的运送。行车规划结束。`,
          icon: 'pi pi-check',
        });
      }
    });
  }

  protected generateTrack() {
    ScheduleService.generateTrack(projectInstance.projectState.name, this.vehicleIndex, 0).then((result) => {
      if (result.currentTrackCount > projectInstance.projectState.vehicleTracks.length) {
        ScheduleService.retrieveSchedule(projectInstance.projectState.name).then((task) => {
          projectInstance.projectState.vehicleTracks = task.vehicleTracks;
          this.updateOrCreateSeries(result);
        });
      } else {
        this.updateOrCreateSeries(result);
      }
      if (result.message && result.message.length > 0) {
        this.$confirm.require({
          header: '规划警告',
          message: result.message,
          icon: 'pi pi-exclamation-triangle',
        });
      }
      if (result.finishedTrack) {
        this.$confirm.require({
          header: '规划结束',
          message: `已完成所有工艺的运送。行车规划初步结束，当前使用了 ${result.currentTrackCount} 辆行车。`,
          icon: 'pi pi-check',
        });
      }
    });
  }

  protected rollback(moveIndex: number = 0) {
    ScheduleService.vehicleRollback(projectInstance.projectState.name, this.vehicleIndex, moveIndex).then((result) => {
      this.updateOrCreateSeries(result);
      this.rollbackVisible = false;
    });
  }

  protected moveHorizontal(direction: string): void {
    let horizontalOffset = 0;
    if (direction === 'Previous') {
      horizontalOffset = -1;
    } else if (direction === 'Next') {
      horizontalOffset = 1;
    }
    ScheduleService.vehicleMoveHorizontal(projectInstance.projectState.name, this.vehicleIndex, horizontalOffset, 0).then((result) => {
      this.updateOrCreateSeries(result);
    });
  }

  protected moveHorizontalToTarget(): void {
    const offset = this.moveHorizontalTarget - this.currentPosition;
    ScheduleService.vehicleMoveHorizontal(projectInstance.projectState.name, this.vehicleIndex, offset, 0).then((result) => {
      this.moveHorizontalTarget = 0;
      this.targetSelectVisible = false;
      this.updateOrCreateSeries(result);
    });
  }

  protected vehicleUp(): void {
    ScheduleService.vehicleMoveUp(projectInstance.projectState.name, this.vehicleIndex, 0).then((result) => {
      this.updateOrCreateSeries(result);
    });
  }

  protected vehicleUpToMiddle(): void {
    ScheduleService.vehicleMoveUpToMiddle(projectInstance.projectState.name, this.vehicleIndex, 0).then((result) => {
      this.updateOrCreateSeries(result);
    });
  }

  protected vehicleDown(): void {
    ScheduleService.vehicleMoveDown(projectInstance.projectState.name, this.vehicleIndex, 0).then((result) => {
      this.updateOrCreateSeries(result);
    });
  }

  protected vehicleWait(waitTime: number): void {
    ScheduleService.vehicleWait(projectInstance.projectState.name, this.vehicleIndex, waitTime).then((result) => {
      this.updateOrCreateSeries(result);
    });
  }

  protected toggleCraftsGraphicVisible() {
    if (this.craftsGraphicVisible) {
      this.clearGraphicOptionsInChart();
    } else {
      this.showGraphicInChart();
    }
    this.craftsGraphicVisible = !this.craftsGraphicVisible;
  }

  private showGraphicInChart() {
    const graphicOptions = this.graphicOptions;
    this.chart.setOption({
      graphic: graphicOptions,
    });
  }

  private clearGraphicOptionsInChart() {
    const graphicOptions: { id: any; $action: string; }[] = [];
    this.graphicOptions.forEach((graphicOption) => {
      graphicOptions.push({
        id: graphicOption.id,
        $action: 'remove',
      });
    });
    this.chart.setOption({
      graphic: graphicOptions,
    });
  }

  private convertCraftsToGraphicOptions() {
    const crafts = projectInstance.projectState.crafts;
    const cycleTime = projectInstance.projectState.cycleTime;
    const graphicOptions: any = [];
    crafts.forEach((craft, index) => {
      if (craft.startTime === -1 || craft.endTime === -1) {
        return;
      }
      const startTime = craft.startTime % cycleTime;
      const endTime = craft.endTime % cycleTime === 0 ? craft.endTime : craft.endTime % cycleTime;
      const optionBase = {
        type: "line",
        z: 0,
        style: {
          lineWidth: 5,
          stroke: "red",
        },
      };
      if (startTime <= endTime) {
        const startPosition = this.chart.convertToPixel({xAxisId: "time", yAxisId: "slots"}, [startTime, craft.belongTo]);
        const endPosition = this.chart.convertToPixel({xAxisId: "time", yAxisId: "slots"}, [endTime, craft.belongTo]);
        const option = {
          id: `craft${index}`,
          ...optionBase,
          position: startPosition,
          shape: {
            x1: 0,
            y1: 0,
            x2: endPosition[0] - startPosition[0],
            y2: 0,
          },
        };
        graphicOptions.push(option);
      } else {
        const startPosition = this.chart.convertToPixel({xAxisId: "time", yAxisId: "slots"}, [startTime, craft.belongTo]);
        const cyclePosition = this.chart.convertToPixel({xAxisId: "time", yAxisId: "slots"}, [cycleTime, craft.belongTo]);
        const endPosition = this.chart.convertToPixel({xAxisId: "time", yAxisId: "slots"}, [endTime, craft.belongTo]);
        const zeroPosition = this.chart.convertToPixel({xAxisId: "time", yAxisId: "slots"}, [0, craft.belongTo]);
        const option1 = {
          id: `craft${index}_1`,
          ...optionBase,
          position: startPosition,
          shape: {
            x1: 0,
            y1: 0,
            x2: cyclePosition[0] - startPosition[0],
            y2: 0,
          },
        };
        const option2 = {
          id: `craft${index}_2`,
          ...optionBase,
          position: zeroPosition,
          shape: {
            x1: 0,
            y1: 0,
            x2: endPosition[0] - zeroPosition[0],
            y2: 0,
          },
        };
        graphicOptions.push(option1);
        graphicOptions.push(option2);
      }
    });
    this.graphicOptions = graphicOptions;
  }

  private updateAllSeries(result: VehicleAutoOperateResult) {
    projectInstance.projectState.vehicleTracks = result.vehicleTracks;
    const slots = result.slots;
    const crafts = result.crafts;
    projectInstance.projectState.slots = slots;
    projectInstance.projectState.crafts = crafts;

    const seriesOptions: LineSeriesOption[] = [];
    result.vehicleTracks.forEach((vehicleTrack, index) => {
      seriesOptions.push({
        name: `行车${index + 1}轨迹`,
        // @ts-ignore
        data: vehicleTrack.movePoints.map((trackPoint) => {
          return [trackPoint.time, trackPoint.slotPosition, trackPoint.pointType];
        }),
        type: "line",
        symbol: (value) => {
          return typeToSymbol(value[2]);
        },
        symbolSize: (value) => {
          return typeToSize(value[2]);
        },
        symbolRotate: (value) => {
          return typeToRotate(value[2]);
        },
        itemStyle: {
          color: (params) => {
            // @ts-ignore
            return typeToColor(params.data[2]);
          },
        },
      });
    });

    const yAxisData = slots.map((slot, index) => {
      if (slot.slotType === SlotType.Normal) {
        return `${index + 1}-${crafts[slot.craftIndex].name}`;
      } else {
        return `${index + 1}-空位`;
      }
    });

    this.clearGraphicOptionsInChart();

    this.chart.setOption({
      yAxis: {
        id: "slots",
        data: yAxisData,
      },
      series: seriesOptions,
    });
    this.$nextTick(() => {
      this.convertCraftsToGraphicOptions();
      this.chart.setOption({
        graphic: this.graphicOptions,
      });
    });
  }

  private updateOrCreateSeries(result: VehicleOperateResult) {
    this.currentTime = result.currentTime;
    this.currentPosition = result.currentPosition;
    result.crafts && (projectInstance.projectState.crafts = result.crafts);
    projectInstance.projectState.vehicleTracks[result.vehicleIndex].movePoints = result.trackPoints || [];
    projectInstance.projectState.vehicleTracks[result.vehicleIndex].moves = result.trackMoves || [];
    // @ts-ignore
    const findIndex = this.chart.getOption().series.findIndex((series) => {
      return series.name === `行车${result.vehicleIndex + 1}轨迹`;
    });

    let seriesOption: LineSeriesOption = {
      name: `行车${result.vehicleIndex + 1}轨迹`,
      // @ts-ignore
      data: result.trackPoints.map((trackPoint) => {
        return [trackPoint.time, trackPoint.slotPosition, trackPoint.pointType];
      }),
    }

    if (findIndex < 0) {
      const baseSeriesOption: LineSeriesOption = {
        type: "line",
        symbol: (value) => {
          return typeToSymbol(value[2]);
        },
        symbolSize: (value) => {
          return typeToSize(value[2]);
        },
        symbolRotate: (value) => {
          return typeToRotate(value[2]);
        },
        itemStyle: {
          color: (params) => {
            // @ts-ignore
            return typeToColor(params.data[2]);
          },
        },
      };
      seriesOption = Object.assign(baseSeriesOption, seriesOption);
      const series = this.chart.getOption().series;
      // @ts-ignore
      series.push(seriesOption);
      this.chart.setOption({
        series: series,
      });
    } else {
      this.chart.setOption({
        series: [seriesOption]
      });
    }
  }

  protected registerEvent(): void {
    bus.$on(Events.ROLLBACK_MOVE, (index: number) => {
      this.rollback(index);
    });
    bus.$on(Events.CRAFTS_MODIFIED, () => {
      this.generateAllTrack();
    });
    this.$on(Events.ADD_CRAFT_TO_CHART, (index: any) => {
      const seriesOption: LineSeriesOption = {
        name: "行车轨迹",
        type: "line",
        data: [],
        color: "red",
      }
    });
  }

  public mounted(): void {
    this.registerEvent();
    this.currentPosition = projectInstance.projectState.vehicleTracks[this.vehicleIndex].currentPosition;
    // @ts-ignore
    const chartDom: HTMLElement = this.$refs.chart;
    this.chart = echarts.init(chartDom);

    const cycleTime = projectInstance.projectState.cycleTime;

    // Y axis data from Slots
    const slots = projectInstance.projectState.slots;
    const crafts = projectInstance.projectState.crafts;
    const yAxisData = slots.map((slot, index) => {
      if (slot.slotType === SlotType.Normal) {
        return `${index + 1}-${crafts[slot.craftIndex].name}`;
      } else {
        return `${index + 1}-空位`;
      }
    });

    const arrayOfTrackPoints: TrackPoint[][] = projectInstance.projectState.vehicleTracks.map((vehicleTrack) => {
      return vehicleTrack.movePoints;
    });
    const options: LineSeriesOption[] = [];
    for (let i = 0; i < arrayOfTrackPoints.length; i++) {
      const trackPoints = arrayOfTrackPoints[i];
      const seriesOption: LineSeriesOption = {
        name: `行车${i + 1}轨迹`,
        type: "line",
        data: [],
        symbol: (value) => {
          return typeToSymbol(value[2]);
        },
        symbolSize: (value) => {
          return typeToSize(value[2]);
        },
        symbolRotate: (value) => {
          return typeToRotate(value[2]);
        },
        itemStyle: {
          color: (params) => {
            // @ts-ignore
            return typeToColor(params.data[2]);
          },
        },
      };
      // @ts-ignore
      seriesOption.data = trackPoints.map((trackPoint) => {
        return [trackPoint.time, trackPoint.slotPosition, trackPoint.pointType];
      });
      options.push(seriesOption);
    }

    const option = {
      legend: {
        data: options.map((option) => option.name),
        top: 10,
        left: 10,
      },
      color: [
        '#008000',
        '#ffff00',
        '#0000ff',
        '#ffa500',
        '#ff3fff',
        '#00ffff'
      ],
      grid: {
        tooltip: {
          show: true,
          // trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
      },
      tooltip: {
        padding: 10,
        borderColor: '#777',
        borderWidth: 1,
        trigger: "axis",
        confine: true,
        // triggerOn: 'axis',
        formatter: function (params: any) {
          let tooltip = '<div style="border-bottom: 1px solid #ccc;font-size: 18px;padding-bottom: 7px;margin-bottom: 7px">' +
            '槽位: ' + params[0].axisValue + '</div>';
          for (let i = 0; i < params.length; i++) {
            const param = params[i];
            let tip = (
              '时间: ' +
              param.data[0] + '秒' +
              '<br>状态: ' +
              typeToText(param.data[2]) + '<br>'
            );
            tooltip += tip + '<hr/>'
          }
          return tooltip;
        }
      },
      xAxis: {
        id: "time",
        name: "时间",
        min: 0,
        max: cycleTime,
        type: 'value',
        // axisLine: { onZero: false }
        axisPointer: {
          label: {
            formatter: function (params: any) {
              return (
                '时间: ' +
                Math.round(params.value) +
                '秒'
              );
            },
          },
        }
      },
      yAxis: {
        id: "slots",
        name: "槽位",
        nameLocation: "start",
        boundaryGap: false,
        type: 'category',
        splitLine: { show: true },
        data: yAxisData,
        inverse: true,
        // axisLine: { onZero: false }
      },
      series: options,
      dataZoom: [
        {
          type: 'slider',
          xAxisIndex: 0,
          filterMode: 'none'
        },
        {
          type: 'slider',
          yAxisIndex: 0,
          filterMode: 'none'
        },
        {
          type: 'inside',
          xAxisIndex: 0,
          filterMode: 'none'
        },
        {
          type: 'inside',
          yAxisIndex: 0,
          filterMode: 'none'
        }
      ],
    };

    this.chart.setOption(option);
    this.$nextTick(() => {
      this.convertCraftsToGraphicOptions();
      this.chart.setOption({
        graphic: this.graphicOptions,
      });
    });
  }
}
