
import {Vue} from "vue-property-decorator";
import {projectInstance} from "@/shared/project-instance";

export default class ResultSuggestions extends Vue {
  get suggestions() {
    const suggestions : any[] = [];
    projectInstance.preliminaryResults.suggestions.forEach((suggestion) => {
      suggestions.push({
        severity: suggestion.level.toLowerCase(),
        content: suggestion.message
      })
    });
    return suggestions;
  }
}
