import {ManageProject, ManageProjectResponse, ProjectPhase} from "@/shared/dtos";
import {client} from "@/shared";

export default class ProjectService {
    public static get(request: ManageProject): Promise<ManageProjectResponse> {
        return client.get(request);
    }

    public static updatePhase(name: string, projectPhase: ProjectPhase): Promise<ManageProjectResponse> {
        return client.put(new ManageProject({
            name,
            projectPhase
        }));
    }
}
